import React, { useState } from "react";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import axios from "axios";

function changeDiv(DPA) {
  const qualificationDiv = document.getElementById("qualification");
  const image = document.getElementById("qImg");
  if (DPA === true) {
    qualificationDiv.innerText =
      "This borrower's address does qualify for the Atlanta-Memphis Program";

    image.src = "/checkmark.png";
  }
  if (DPA === false) {
    qualificationDiv.innerText =
      "This borrower's address does not qualify for the Atlanta-Memphis Program ";

    image.src = "/x2.png";
  }
}

function App() {
  function handlePlacesChanged() {
    const image = document.getElementById("qImg");
    const qualificationDiv = document.getElementById("qualification");
    image.src = "";
    qualificationDiv.innerText = "Loading...";
    qualificationDiv.style.color = "black";
    if (searchBox) {
      const places = searchBox.getPlaces();

      const census = places.map((place) => {
        const position = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        return position;
      });
      const lat = census[0].lat;
      const lng = census[0].lng;

      axios
        .post(
          "https://check.terravalue.com/qualify",
          {
            lat: lat,
            lon: lng,
          }
          // {
          //   headers: {
          //     "Access-Control-Allow-Origin": "*",
          //     "Content-Type": "application/json",
          //   },
          // }
        )
        .then(function (response) {
          console.log(response);
          changeDiv(response.data.qualify);
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  }

  const [searchBox, setSearchBox] = useState(null);
  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };

  return (
    <>
      <div style={{ textAlign: "center", marginTop: "100px" }}>
        <h1>Program Qualification Tool</h1>
        <div>
          <LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyCWedJRxOp8nbE5hZhAxS43oDgCHGvGB-0"
            libraries={["places"]}
          >
            <StandaloneSearchBox
              onLoad={onSBLoad}
              onPlacesChanged={handlePlacesChanged}
            >
              <input
                type="text"
                placeholder="Enter you location"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `350px`,
                  height: `32px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                }}
              />
            </StandaloneSearchBox>
          </LoadScript>
        </div>

        <h3 id="qualification" className="mt-5 ml-10" fontSize="100px">
          Type a property address to check eligibility
        </h3>
        <img id="qImg" alt="" />
        <footer
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: "60px",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ margin: 0 }}>
            Eligibility requirements apply. See a local loan originator for more
            information. This is not a commitment to lend.
          </p>
        </footer>
      </div>
    </>
  );
}

export default React.memo(App);
